import React from "react";
import { Link } from 'react-router-dom';
import { Row, Container, Col, Card } from 'react-bootstrap';
import logo from '../assets/images/logo.png';

export default function Home(props) {
    
    return (
        <React.Fragment>
            <Container>
                <div className='pt-4 pb-2 text-center'>
                    <Link to=""><img src={logo} alt="" height={60} /></Link>
                </div>
            </Container>
            <div className="mt-3">
                <Container>
                    <Row className="justify-content-md-center bookboxm">
                        <Col md="4">
                            <div className="cardBox mb-4">
                                <Link to="#">
                                    <Card className="post">
                                        <Card.Body>
                                            <div className="d-flex align-items-center gap-3">
                                                <div className="listIcon">
                                                    <i className="fa-regular fa-rectangle-list"></i>
                                                </div>
                                                <div className="text-center">
                                                    <h5>Today Booking</h5>
                                                    <h2>{props.todayBookingCount}</h2>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="cardBox mb-4">
                                <Link to="#">
                                    <Card className="post">
                                        <Card.Body>
                                            <div className="d-flex align-items-center gap-3">
                                                <div className="listIcon">
                                                    <i className="fa-regular fa-chart-bar"></i>
                                                </div>
                                                <div className="text-center">
                                                    <h5>Lifetime  Booking</h5>
                                                    <h2>{props.totalBookingCount}</h2>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* Equipment Wise Bookings */}
               
                {/* Equipment Wise Bookings end */}
            </div>
        </React.Fragment>
    )
}