import React from "react";
import { Row, Container, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function EquipmentBookingStatus(props) {
console.log(props);
    

    return (
        <React.Fragment>
            <Container fluid>
                <div className="mt-3 mb-3">
                    <h2 className="text-white text-center">Equipment Wise Bookings</h2>
                </div>
                <Row>
                {props.equipments.map(equipment => (  
                    <Col md="4" className="mb-4" key={equipment.id}>
                        <Card className="EquipmentsBox h-100">
                            <Link to="#">
                                <Card.Body>
                                    <Row className="align-items-center">
                                        <Col md="12" className="text-center">
                                            <h5>{equipment.fullName}</h5>
                                        </Col>
                                    </Row>
                                    <Row className="border-top mt-3 text-center pt-3">
                                        <Col>
                                            <p>Today Booking</p>
                                            <div className="d-grid">
                                                <Button variant="btn btn-outline-primary mt-2">{equipment.todayBookingCount}</Button>
                                            </div>
                                        </Col>
                                        <Col>
                                            <p>Overall Booking status</p>
                                            <div className="d-grid">
                                                <span className="btn btn-outline-danger mt-2">{equipment.totalBookingCount}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                    ))}  
                </Row>
            </Container>
        </React.Fragment>
    )
}