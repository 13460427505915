
import React, { useEffect, useState } from "react";
import './App.css';
import './assets/css/all.min.css';
import './assets/css/Style.scss';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import EquipmentBookingStatus from './pages/EquipmentBookingStatus';
const axios = require('axios');
function App() {
  const [todayBookingCount, setTodayBookingCount] = useState(0);
  const [totalBookingCount, seTotalBookingCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [allequipments, setAllequipments] = useState([]);
  // Make a request for a user with a given ID
  const getdata = () => {
    axios.get('https://sathibhu.org/Api/allBookingCount')
      .then(function (response) {
        // handle success
        console.log(response);
        setTodayBookingCount(response.data.todayBookingCount);
        seTotalBookingCount(response.data.totalBookingCount);
        // getequipments();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }


  const getequipments = () => {
    axios.get('https://sathibhu.org/Api/equipmentList')
      .then(function (response) {
        // handle success
        //console.log(response);
        setAllequipments(response.data.equipments);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }


  useEffect(() => {
    const bundleFunctionCall = () => {
      getdata();
      getequipments();
    }


    setRefreshInterval(500);
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(bundleFunctionCall, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);
  return (
    <div className="App">

      <Routes>
        <Route path="/" index element={<Home totalBookingCount={totalBookingCount} todayBookingCount={todayBookingCount} />} />
        {/* <Route path="about-the-department" element={} /> */}
      </Routes>
      <EquipmentBookingStatus equipments={allequipments} />
    </div>
  );
}

export default App;
